@import url('https://www.w3schools.com/w3css/4/w3.css');

html {
  min-height: 100%;
}

.App {
  font-family: sans-serif;
  text-align: left;
  max-width: 1000px;
  margin: auto;
  
}

.header{
  background-image: url('./images/header.png');
  background-color: #e0e0e0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 300px; 
  max-height: 500px; 
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .mainTitle{
    display: flex;
    align-items: flex-end;
    color: white;
    h1{
      text-shadow:1px 1px 0 #444;
      font-weight: 800;
      font-size: 40px;
    }    
  }
  .rightPanel{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .RSS{
      display: flex;
      align-items:flex-start;
    }
    .supportus{
      display: flex;
      align-items: flex-end;
      img{
        width: 100%;
        max-width: 200px;
        height: auto;      
      }
    }
  }
}

.searchbar{
  margin: 2px 0;
}

.episode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  background-color: #212527;
  margin: 1px 0;
  //min-height: 70px;

  .title {
    user-select: none;
    padding: 0 20px;
    //justify-content: flex-start;
    .title__date {
      text-align: left;
      margin: 0;
      color: white;
      //font-family: "Permanent Marker", cursive;
      font-weight: normal;
      font-size: 32px;

    }

    .title__title {
      margin: 0;
      color: white;
      //font-family: "Rock Salt", cursive;
      //font-weight: normal;
      font-size: 24px;
    }

  }
  .control{
    display: flex;
    align-items: center;
  }
  .button {
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-size: 20px;
  }

}

@media screen and (max-width: 800px) {
  .header{
    padding: 0 10px;
    min-height: 200px; 
    .mainTitle{
      h1{
        font-size: 34px;
      }    
    }
    .rightPanel{
      .RSS{
        h2{
          font-size: 26px;
        }
      }
      .supportus{
        h3{
          font-size: 26px;
        }
        img{
          max-width: 150px;  
        }
      }
    }      
  }     
  .episode{  
    .title {
      margin: 0;
      .title__date {
        font-size: 24px;
      }

      .title__title {
        font-size: 20px;
      }
    }
    .control{
      .button{
        font-size: 16px;
      }
    }
  }          
}

@media screen and (max-width: 600px) {
  .header{
    .mainTitle{
      h1{
        font-size: 24px;
      }    
    }
    .rightPanel{
      justify-content: flex-end;
      .RSS{
        h2{
          font-size: 20px;
        }
      }
      .supportus{
        h3{
          font-size: 20px;
        }
      }
    }      
  }     
  .episode{
  flex-direction: column;
  align-items:baseline;
  padding: 10px 10px;     
    .title {
      margin: 0 0 0 10px;
      padding: 0;
      .title__date {
        font-size: 24px;
      }

      .title__title {
        font-size: 20px;
      }
    }
    .control{
      .button{
        font-size: 16px;
      }
      .w3-btn{
        padding: 4px 8px;
      }
    }
  }      
}

@media screen and (max-width: 450px) {
  .header{
    min-height: 150px; 
    .mainTitle{
      h1{
        margin: 5px 0;
        font-size: 24px;
      }    
    }
    .rightPanel{
      justify-content: flex-end;
      .RSS{
        h2{
          margin: 5px 0;
          font-size: 16px;
        }
      }
      .supportus{
        h3{
          margin: 5px 0;
          font-size: 16px;
        }
        img{
          max-width: 100px;   
        }
      }
    }      
  }
  .episode{    
      .title {
        .title__date {
          font-size: 20px;
        }
  
        .title__title {
          font-size: 18px;
        }
      }
      .control{
        .button{
          font-size: 14px;
        }
      }       
    }
}

@media screen and (max-width: 320px) {
  .header{
    min-height: 100px; 
    .mainTitle{
      h1{
        margin: 5px 0;
        font-size: 20px;
      }    
    }
    .rightPanel{
      justify-content: flex-end;
      .RSS{
        h2{
          margin: 5px 0;
          font-size: 12px;
        }
      }
      .supportus{
        h3{
          margin: 5px 0;
          font-size: 12px;
        }
        img{
          max-width: 80px;   
        }
      }
    }      
  }
  .episode{    
      .title {
        .title__date {
          font-size: 18px;
        }
  
        .title__title {
          font-size: 14px;
        }
      }
      .control{
        .button{
          font-size: 14px;
        }
        .w3-btn{
          padding: 2px 4px;
        }
      }      
    }
}

.audio-lists-panel{
  right: 0 !important;
  max-width: 480px !important;
  width: 100% !important;
  height: 50% !important;
}

@media screen and (max-width: 767px){
  div.react-jinke-music-player-main .music-player-panel { 
    height: 100px !important;
    
    .panel-content {
      flex-direction: column !important;
      .progress-bar-content {
        display:block !important;
        padding: 5px 0;
        width: 100%;    
      }
      .player-content{
        padding: 5px 0;
      }
    }
  }
}